@import url(../../css/variables.css);

.chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 24px;
}

.playerGradientBg {
  background: linear-gradient(180deg, #fd9494, #ec0b0b);
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  position: fixed;
}

.cdBg {
  background-size: 550px 550px;
  background-repeat: no-repeat;
  /* background-image: url(./backgroundCD.png); */
  background-position: center;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.downArrowDiv {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downArrow {
  color: #f50101;
  font-size: 28px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 0px;
  align-items: center;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.musicTitle {
  font-size: var(--heading);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  margin-top: 14px;
}

.musicSubTitle {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: black;
  margin-top: 8px;
}

.player {
  font-size: var(--text);
  font-weight: 400;
  font-family: Inter;
  color: #d9d9d9;
  margin-top: 6px;
}

.thumbnail {
  width: 300px;
  height: 300px;
  margin-top: 10px;
}

.queueIcon {
  color: #ffffff;
  font-size: 28px !important;
  cursor: pointer;
}

.videoListDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding-left: 20px; */
}

.queueDiv {
  width: 100%;
  margin-top: 15px;
}

.textColor {
  color: #6a0000;
  font-size: var(--title);
  font-weight: 600;
  font-family: Inter;
}

.queueText {
  color: #ffffff;
  font-size: var(--subTitle);
  font-weight: 400;
  font-family: Inter;
  /* font-size: 36px; */
  font-style: normal;
  line-height: normal;
}

.queueTitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.videoTitleText {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: var(--title);
  color: #ffffff;
  font-weight: 600;
  font-family: Inter;
  max-width: 70%;
}

.flexDr {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.videoFlexDr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.videoIcons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-right: 13px;
}

.videoIconsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.videoIconsSubDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bgColor {
  background-color: #ff0000;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-right: -15px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  align-items: center;
}

.rightMg {
  padding-right: 30px !important;
}

.commentsIcon {
  height: 24px;
  width: auto;
  cursor: pointer;
}

.vtIconText {
  color: #ffffff;
}

.vtArtistText {
  color: #ffffff;
  font-size: var(--text);
}

.vtArtistDetails {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.vtCategoriesSubText {
  color: #ffffff;
  font-size: var(--subTitle);
}

.vtViews {
  color: #bebebe;
  font-size: var(--body);
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.vtVideoDescription {
  color: #ffffff;
  font-size: var(--body);
  margin-top: 5px;
}


.vtVideoDescription p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.vtVideoDescription table,
.vtVideoDescription tbody,
.vtVideoDescription tr,
.vtVideoDescription td {
  max-width: 100%;
}

.vtVideoDescription a {
  color: yellow !important;
}

.vtVideoDescription a span {
  color: yellow !important;
}

.vtVideoDescription img {
  max-width: 100%;
  height: auto;
}


.vtDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.vtSubDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 50%;
}

.vtArtistDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
}

.disc {
  color: #fff !important;
  background-color: #ff0000 !important;
  font-size: 50px !important;
  width: 50px;
  height: 50px;
}

.categories {
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.categories:hover {
  color: #ff0000;
}

@media only screen and (max-width: 431px) {
  .videoTitleText {
    max-width: 100%;
  }
  .vtDiv {
    flex-direction: column;
    gap: 20px;
  }

  .vtSubDiv {
    width: 100%;
  }

  .videoIconsDiv {
    width: 100%;
    margin-right: 0;
  }

  .queueDiv {
    width: 100%;
    margin-top: 20px;
  }

  .rightMg {
    padding-right: 0 !important;
  }

  .videoIconsSubDiv {
    width: auto;
  }

  .videoFlexDr {
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
  .downArrowDiv {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .title {
    margin: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 5%;
  }

  .cdBg {
    background-position: left;
    background-position-x: 150px;
  }

  .musicTitle {
    margin-top: 6%;
  }

  .musicSubTitle {
    margin-top: 3%;
  }

  .player {
    margin-top: 4%;
  }

  .mainDiv img {
    width: 90%;
    height: auto;
    margin-top: 7%;
  }

  .queueTitleDiv {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .moreFromText {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
