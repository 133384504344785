@import url(../../css/variables.css);

.appbarContainer {
  background-color: #000 !important;
  width: 100%;
  min-height: 12vh;
  border-bottom: 1px solid rgb(212, 0, 0);
}

.fullHeightAds {
  display: flex !important;
  justify-content: center;
}

.BannerAds {
  width: 80%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
}

.BannerAds ins {
  width: 100% !important;
  max-width: 100%;
  height: auto !important;
}

.fullHeight {
  height: 100%;
}

.toolbarHeight {
  height: 100%;
}

.tjjLogo {
  width: auto;
  height: 55.8px;
  cursor: pointer;
}

.navContainer {
  flex-grow: 1;
  display: none;
  justify-content: flex-end;
  align-items: center;
}

.searchIcon {
  color: #fff;
  font-size: 30px !important;
  cursor: pointer;
}

.buttonNav {
  color: #fff !important;
  display: block;
  font-size: var(--body) !important;
  font-weight: 700 !important;
  font-family: Inter !important;
  transition: all 0.3s ease-out !important;
  text-wrap: wrap;
  text-align: left;
}

.buttonNav:hover {
  color: #ff0000 !important;
}

.mdBox {
  flex-grow: 1;
  display: flex;
  margin-left: 15px;
  gap: 2px;
}

.searchIconMd {
  font-size: 22px !important;
  display: flex;
  cursor: pointer;
}

.search {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease-out;
  font-size: var(--body);
  font-weight: 700;
  font-family: Inter;
}

.NavElements {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.NavElements:hover .caretDown {
  color: #ff0000 !important;
}

.NavElements:hover .buttonNav {
  color: #ff0000 !important;
}

.search:hover {
  color: #ff0000;
}

.caretDown {
  font-size: 18px !important;
  color: #fff;
  cursor: pointer;
  margin-left: -5px;
}

.display {
  display: flex;
  max-width: 300px;
}

.socialsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.icon {
  font-size: 20px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.profileIcon {
  font-size: 30px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-left: 10px;
}
.profileIcon:hover {
  color: #ff0000;
}

.icon:hover {
  color: #ff0000;
}

@media only screen and (max-width: 1024px) {
  .BannerAds {
    width: 100%;
  }

  .navContainer {
    display: flex;
  }

  .mdBox {
    display: none;
  }

  .searchIconMd {
    display: none !important;
  }

  .search {
    display: none;
  }
  .display {
    display: none;
  }
  .tjjLogo {
    width: 100px; /* Adjust the width for smaller screens */

    height: auto; /* Maintain aspect ratio */
  }
}

@media only screen and (max-width: 431px) {
  .fullHeightAds{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
