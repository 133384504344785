@import url(../../css/variables.css);

.heading {
  font-size: var(--title);
  font-weight: 700;
  font-family: Inter;
  text-align: center;
  color: #fff;
}

.tabDiv {
  position: relative;
  margin-top: 50px;
}

.mgb {
  margin-bottom: 40px;
}


.leftShadow {
  height: 48px;
  width: 10px;
  position: absolute;
  left: -35px;
  background: linear-gradient(
    to right,
    #fff 2%,
    rgba(255, 255, 255, 0.3) 40%,
    transparent
  );
}

.rightShadow {
  height: 48px;
  width: 10px;
  position: absolute;
  right: -35px;
  top: 0;
  background: linear-gradient(
    to left,
    #fff 2%,
    rgba(255, 255, 255, 0.3) 40%,
    transparent
  );
}

.tabPanel {
  margin-top: 40px;
}

.SearchDiv {
  display: flex;
  width: 100%;
  position: relative;
}

.noDataFound {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: var(--heading);
  gap: 20px;
  font-family: Inter;
  font-weight: 600;
  text-align: center;
}

.noDataImg {
  height: 150px;
  width: auto;
}

.artist {
  display: flex;
  align-items: center;
  font-size: var(--smallText);
  font-family: Inter;
  font-weight: 500;
  color: #c8c8c8;
}

.albumsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.albumsSubDiv {
  width: auto;
}

.mainDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.viewDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5;
  font-size: var(--text);
  color: #fff;
  font-family: Inter-Regular;
  font-weight: 400;
  cursor: pointer;
}

.ChevronRightIcon {
  font-size: var(--title);
  color: #fff;
}

.allDiv {
  display: flex;
  flex-direction: column;
 
}

.filterSm {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.gridContainer{
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.gridContainer2{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.filter {
  padding: 20px;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calendarHead {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
}
.date {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
}

.to {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.filterDiv{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.closeIcon{
  display: none !important;
  font-size: 18px !important;
  color: #fff;
  cursor: pointer;
}
.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 900px) {
  .leftShadow {
    display: none;
  }

  .rightShadow {
    display: none;
  }

  .albumsSubDiv {
    width: 47%;
  }

  .filterDiv{
    justify-content: space-between;
  }

  .closeIcon{
    display: flex !important;
  }
}
