@import url(../../css/variables.css);

.reactModalSheetContainer {
  height: 100% !important;
}

.miniMusicDiv {
  width: 100%;
  background-color: #212121;
  border-radius: 42px;
  position: relative;
}

.miniMusicSubdiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
  height: 125px;
  position: relative;
}

.caret {
  color: #d9d9d9;
  font-size: 25px !important;
}

.fg {
  flex-grow: 1;
}

.caretDiv{
  display: flex;
  justify-content: center;
  width: 100%;
  position:absolute;
  top:-20px;
  z-index: 999999;
}

.miniMusicSeekBar {
  height: 5px;
  background-color: #d9d9d9;
  position: absolute;
  top: 0px;
}

.fdr {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.miniMusicImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.fdc {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.miniMusicTitle {
  font-size: var(--heading);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
}

.miniMusicSubTitle {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: #ff0000;
  margin-top: 7px;
}

.miniMusicDuration {
  font-size: var(--text);
  font-weight: 400;
  font-family: Inter;
  color: #d9d9d9;
  margin-top: 7px;
}

@media only screen and (max-width: 431px) {
  .miniMusicDiv {
    border-radius: 15px;
  }

  .caretDiv{
    top: -15px;
  }

  .fdr{
    padding-left: 7px;
  }

  .caret {
    font-size: 22px !important;
  }

  .miniMusicSubdiv {
    margin-right: 15px;
    margin-left: 40px;
    height: 100px;
    position: relative;
  }

  .miniMusicSeekBar {
    height: 3px;
    background-color: #d9d9d9;
    position: absolute;
    top: 0px;
  }

  .miniMusicImage {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .miniMusicTitle {
    font-size: var(--body);
  }

  .miniMusicSubTitle {
    font-size: var(--text);
    margin-top: 4px;
  }
  .miniMusicDuration {
    font-size: var(--smallText);
    margin-top: 4px;
  }
}
