@import url(../../css/variables.css);

.flexDrC {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.flexDr {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.subText {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
}

.oneLineText {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.artist {
  display: flex;
  align-items: center;
  font-size: var(--smallText);
  font-family: Inter;
  font-weight: 500;
  color: #c8c8c8;
}

.eventsHeading {
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 700;
  color: #ff0000;
  margin-bottom: 20px;
}

.songListDiv {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
}

.locationIcon {
  color: #ff0000;
  font-size: 14px !important;
}

.calendarIcon {
  color: #ff0000;
  font-size: 12px !important;
}

.mgb {
  margin-bottom: 30px !important;
}

.mgb20 {
  margin-bottom: 20px !important;
}
.mgb10 {
  margin-bottom: 10px;
}

.topBoxTag {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 15px;
}

.top {
  color: #fff;
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.topBold {
  color: #fff;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.top:hover {
  color: #ff0000;
}
.topBold:hover {
  color: #ff0000;
}

.artistsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.artistsSubDiv {
  width: auto;
}

.genreCard {
  height: 260px;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  color: #fff;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 700;
  cursor: pointer;
}
.yearCard {
  height: 260px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
  position: relative;
}

.yearText {
  color: #fff;
  font-size: var(--h1);
  font-family: Inter;
  font-weight: 700;
}

.galleryContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3px;
}

.ratingCarnival {
  display: flex;
  align-items: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 400;
  color: #fff;
}

.star {
  color: yellow;
  font-size: 22px;
  margin-left: 2px;
  margin-right: 2px;
}

.albumsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.albumsSubDiv {
  width: auto;
}

.name {
  cursor: pointer;
}

.name:hover {
  text-decoration: underline;
}

.widFull{
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .name {
    text-decoration: underline;
  }
  .albumsSubDiv {
    width: 46%;
  }
}

@media only screen and (max-width: 431px) {
  .yearCard {
    height: 159px;
  }
  .mgb20 {
    margin-bottom: 40px !important;
  }

  .artistsSubDiv {
    width: 47%;
  }

  .albumsSubDiv {
    width: 46%;
  }

  .genreCard {
    height: 180px;
  }
}
