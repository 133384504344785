@import url(../../css//variables.css);

.slickDots {
  bottom: -120px;
  display: flex !important;
  flex-direction: row;
  overflow-x: auto;
  gap: 5px;
  position: absolute;
  width: 100%;
  cursor: pointer;
}

.slickDots::-webkit-scrollbar{
  width: 1px !important;
}

.slickDots::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
.slickDots::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 20px;
}

/* Handle on hover */
.slickDots::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.slickDots li {
  width: auto;
  height: auto;
  list-style: none;
}

.container {
  width: 100%;
  height: 750px;
  overflow: hidden;
}

.dots {
  width: 200px;
  height: 100px;
  overflow: hidden;
}

.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.carouselImageDiv {
  width: 100%;
  height: 600px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.carouselTitle{
  background-color: rgba(0, 0, 0, 0.5);
  font-size: var(--h1);
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: #fff;
  padding: 10px;
  font-weight: 700;
  margin-right: 20px;
}

@media only screen and (max-width: 900px) {
  .container {
    height: auto;
    max-height: 956px;
    overflow: hidden;
    padding-bottom: 100px;
    margin-bottom: 40px;
  }

  .carouselImageDiv {
    height: auto;
    max-height: 856px;
  }
}

@media only screen and (max-width: 431px) {
  .slickDots {
    bottom: -90px;
  }

  .container {
    height: 300px;
    overflow: hidden;
  }

  .dots {
    width: 100px;
    height: 70px;
    overflow: hidden;
  }

  .carouselImageDiv {
    height: 200px;
  }

  .carouselTitle{
    font-size: var(--body);
    left: 10px;
    bottom: 10px;
    margin-right: 10px;
  }
}
