@import url(../../css/variables.css);

.eventImageDiv {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.eventImage {
  width: 100%;
  height: 100%;
  position: relative;
}

.eventImageD {
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: start;
  min-width: 170px;
  height: auto;
  min-height: 237px;
}

.eventContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.eventName {
  font-size: var(--heading);
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.eventName:hover {
  color: #ff0000;
}

.imgMainDiv:hover + div .eventContent .eventName {
  color: #ff0000;
}

.mainDiv {
  transition: all 0.3s ease-out;
}

.mainDiv:has(.eventImageDiv:hover) {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0px 2px 5px 0px #171717;
}

.mainDiv:has(.eventName:hover) {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0px 2px 5px 0px #171717;
}

.eventCarnivalName {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  margin-bottom: 17px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.eventCarnivalName:hover {
  color: #ff0000;
}

.eventShortAddress {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  text-overflow: ellipsis;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.eventShortAddress span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}
.eventShortAddressA {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  text-overflow: ellipsis;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.eventShortAddressA span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.eventShortAddress:hover {
  color: #ff0000;
}

.eventChip {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  margin-top: 15px;
}
.eventChips {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  margin-top: 15px;
}

.eventNumber {
  font-size: var(--text);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.eventNumber span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}
