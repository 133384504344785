@import url(../../css/variables.css);

.subText {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
}

.likeCount {
  font-size: 20px;
  font-family: "Inter",sans-serif;
  color: #fff;
  margin-left: 1px;
}

.locationText {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.mgb {
  margin-bottom: 20px;
}
.mgb10 {
  margin-bottom: 10px;
}
.mgb40 {
  margin-bottom: 40px;
}

.images {
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-wrap: wrap;
}

.flexDrC {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.loadImages {
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-wrap: wrap;
}

.mainDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.mainDivMgb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;
}

.viewDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: var(--text);
  color: #fff;
  font-family: Inter-Regular;
  font-weight: 400;
  cursor: pointer;
}

.ChevronRightIcon {
  font-size: var(--title) !important;
  color: #fff;
}

.locationOnIcon {
  color: #ff0000;
  font-size: 14px !important;
}

.calendarIcon {
  color: #ff0000;
  font-size: 12px !important;
}

.slidesDiv {
  width: 100%;
}

.filter {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calendarHead {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
}
.date {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
}
.mgt10 {
  margin-top: 10px;
}
.to {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.secondContainer {
  width: 100%;
  padding-left: 20px;
}

.flexDr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.iconsSm {
  display: none;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
}

.iconsSmDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.filterSm {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.upDown {
  font-size: 14px !important;
  color: #fff;
}

.gridContainer2 {
  padding-left: 30px;
}

.ratingSubDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

.ratingBoxDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.contentDiv {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;
}

.gridGeneral {
  border-right: 1px solid #ff0000;
  padding-right: 20px;
  display: flex;
  flex-direction: column !important;
  gap: 10px;
}

.gridGeneralL {
  border-left: 1px solid #ff0000;
  padding-left: 20px;
  display: flex;
  flex-direction: column !important;
  gap: 20px;
}

.spacingLR {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column !important;
  gap: 20px;
}

.timeFontIcon {
  font-size: 18px !important;
  color: #ff0000;
}

.flexDR {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.locationIcon {
  font-size: 20px !important;
  color: #ff0000;
}

.imgIcon {
  min-height: 200px;
  height: auto;
  width: 100%;
  margin-top: 10px;
}

.headingStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  gap: 6px;
}

.headingStyleMgb {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  gap: 6px;
  margin-bottom: 20px;
}

.contentStyle {
  color: #fff;
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.calendarImage {
  height: 30px;
  width: auto;
  cursor: pointer;
}

.flexGap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.moreText {
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: var(--body);
  margin-bottom: 5px;
  margin-top: 10px;
}

.moreTitle {
  font-weight: 600;
  font-family: Inter;
  min-width: 200px;
}

.moreDescription {
  font-weight: 400;
  font-family: Inter;
  cursor: pointer;
  color: #fff;
  transition: all 0.3s ease-out;
}

.moreDescription:hover {
  color: #ff0000;
  font-weight: 500;
}

.galleries {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.gridHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actionButtons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.actionButtonsDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sortBy {
  width: 200px;
}
.ascDescIcon {
  font-size: 18px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1 {
  font-size: 18px !important;
  color: #ff0000;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1:hover {
  color: #fff;
}

.ascDescIcon:hover {
  color: #ff0000;
}

.highlightedText {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 600;
  overflow-wrap: break-word;
  word-break: break-all;
  cursor: pointer !important;
  transition: all 0.3s ease-out;
  margin-top: 10px;
}

.noHighlightedText {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 600;
  overflow-wrap: break-word;
  word-break: break-all;
  transition: all 0.3s ease-out;
  margin-top: 10px;
}

.highlightedText:hover {
  color: #ff0000;
}
.redirectIcon {
  padding-top: 3px;
}
.sortDiv {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.likeButton {
  margin: 6px;
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.flexDr2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 20px;
}

.smBanner {
  display: none;
}

.BannerAds {
  width: 100%;
  height: auto;
  margin-top: 30px;
  max-width: 100%;
  overflow: hidden;
}

.BannerAds ins{
  width: 100% !important;
  max-width: 100%;
  height: auto !important;
}


@media only screen and (max-width: 900px) {
  .BannerAds {
    margin-bottom: 30px;
  }

  .smBanner {
    display: flex;
  }

  .slidesDiv {
    width: 95%;
  }

  .gridContainer2 {
    padding-left: 0;
    margin-top: 40px !important;
  }

  .secondContainer {
    display: none;
  }

  .flexDr {
    justify-content: space-between;
  }

  .iconsSm {
    display: flex;
  }

  .spacingLR {
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .gridLeftPadding {
    padding-left: 0px;
  }

  .gridGeneral {
    border-right: 0;
    padding-right: 0;
  }

  .sortBy {
    width: 80%;
  }

  .gridGeneralL {
    border-left: 0;
    padding-left: 0;
    gap: 30px;
  }
}
