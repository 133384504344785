.div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconDiv {
  display: flex;
  color: #ff0000;
  margin-right: 5px;
  width: 20px;
  font-size: 16px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.top {
  color: #fff;
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.top:hover {
  color: #ff0000;
}
