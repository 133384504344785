@import url(../../css/variables.css);

.flexDr {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.iconsWrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.flexDrR1 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;

}
.gridContainer1 {
  padding-right: 15px;
}

.artistImage {
  width: 100%;
  height: auto;
}
.albumsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.albumsSubDiv {
  width: auto;
}

.noDataText{
  display: flex;
  color: #ffffff;
  font-family: "Inter",sans-serif;
  font-weight: 700;
  font-size: var(--body);
  justify-content: center;
  align-items: center;
  text-align: center;
}


.vtIconText {
  color: #ffffff;
  font-family: "Inter",sans-serif;
}

.vtArtistText {
  color: #ffffff;
  font-size: var(--subTitle);
  font-family: Inter;
}

.vtArtistDescription {
  color: #ffffff;
  font-size: var(--body);
  font-family: Inter;
  padding-right: 5px;
  margin-top: 20px;
}

.vtArtistFollow {
  color: #ffffff;
  font-size: var(--body);
  font-family: Inter;
  padding-right: 5px;
  margin-top: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.follow{
  color: #fff;
  font-size: 20px !important;
  font-weight: 600;
}

.tabsMainDiv {
  flex-grow: 1;
  display: flex;
  height: auto;
  border-right: 1px solid red;
  margin-top: 20px;
  padding-right: 10px;
}

.videosTab {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}


.trendingDiv{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playDiv{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: rgba(185, 6, 6, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.mgT{
  margin-top: 40px;
}

.subText {
  text-align: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
}

.icon {
  font-size: 22px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.icon:hover {
  color: #ff0000;
}

.imgSocial {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .gridContainer1 {
    padding-right: 0;
  }

  .videosTab{
    flex-wrap: wrap;
  }

  .flexDrR1{
    gap:0px;
  }
  .tabsMainDiv {
    border-right: 0;
    margin-bottom: 30px;
    padding-right: 0;
  }
  .albumsSubDiv {
    width: 46%;
  }
}
