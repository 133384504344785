@import url(../../css/variables.css);

.subTitle{
    font-size: var(--body);
    color: #ffffff;
    margin-top: 3;
    font-weight: 500;
    font-family: Inter;
    text-align: center;
}

.mgb{
    margin-bottom: 40px;
}

.flexDr{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}
.flexDrR{
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}


.albumsDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .albumsSubDiv {
    width: auto;
  }
  @media only screen and (max-width: 431px) {
    
    .albumsSubDiv {
      width: 47%;
    }
    .flexDrR{
      gap:0px;
    }
    
    }
  