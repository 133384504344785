.player {
    &__seek-bar {
        width: 100%;
        &--wrapper {
            background-color: $color-grey-transparent;
            height: $height-seek-bar;
            border-radius: 50rem;
            position: relative;
            flex: 1;
        }
        appearance: none;
        background-color: transparent;
        &::-webkit-slider-thumb {
            appearance: none;
            z-index: 10;
            height: $height-seek-bar-thumb;
            width: $height-seek-bar-thumb;
            background-color: $color-red;
            box-shadow: $shadow-seek-thumb;
            border-radius: $border-radius-circle;
            transform: translateY(-25%);
        }

        &::-moz-range-thumb {
            appearance: none;
            z-index: 10;
            height: $height-seek-bar-thumb;
            width: $height-seek-bar-thumb;
            background-color: $color-white;
            box-shadow: $shadow-seek-thumb;
            border-radius: $border-radius-circle;
            border: none;
            transform: translateY(-25%);
        }

        &--gradient {
            max-width: 100%;
            height: $height-seek-bar;
            position: absolute;
            top: 0;
            border-radius: 50rem;
        }

        flex: 1;
    }
}
