@import url(../../css/variables.css);

.overallRatingDiv {
  width: 100%;
  background-color: #1a1a1a;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  flex-direction: column;
}

.overallHeading {
  font-size: var(--heading);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  margin-bottom: 20px;
}

.gridBorderRight {
  border-right: 1px solid #fff;
}

.overallRatingSubDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: baseline;
  gap: 20px;
}

.overallRatingHead {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: #fff;
}

.overallRating {
  font-size: var(--h1);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

@media only screen and (max-width: 431px) {
  .gridBorderRight {
    border-right: 0;
  }
}
