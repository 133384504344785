.absolute {
    position: fixed;
    z-index: 99;
    height: 50px;
    bottom: 150px;
    right: 20px;
    border-radius: 50%;
    background-color: red;
    display: flex; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    cursor: pointer;
  }
  .absolute:hover {
    background-color: white;
    transition: all .3s ease-out ease-in;
  }
  
  .icon {
    font-size: 50px;

    
    color: white;
    animation: pulse 1s infinite alternate; /* Apply the animation */
  }
  
  .icon:hover {
    color: red;
    transition: all .3s ease-out;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
  
  /* Media query for smaller phones */
  @media (max-width: 480px) {
    .absolute {
      height: 40px;
      width: 40px;
      bottom: 130px;
      right: 20px;
    }
  
    .icon {
      font-size: 50px;
    }
  }
  