@import url(../../css/variables.css);

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.MuiMenu-paper {
  background-color: #1f1f1f !important;
  color: #fff !important;
}

.MuiMenuItem-root {
  font-size: var(--text) !important;
  font-family: "Inter", sans-serif !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}
