.noDataDiv {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.noDataButton {
  position: absolute;
  bottom: 100px;
}

@media only screen and (max-width: 900px) {
  .noDataButton {
    bottom: 40px;
  }
}

@media only screen and (max-width: 700px) {
  .noDataButton {
    bottom: 0px;
  }
}
