@import url(../../css/variables.css);

.imgDiv {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.thumbnail {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: fill;
}

.playIconDiv {
  position: absolute;
  background-color: rgba(185,6,6,0.73);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playIcon {
  width: 23px;
  height: 25px;
  object-fit: cover;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: var(--heading);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-top: 5px;
}

.title:hover{
  color: #ff0000;
}

.subTitle {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: #989898;
  margin-top: 5px;
}
.subTitleP {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: #989898;
}

.footerDiv {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.footerDivS {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.footerDivP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
}

.flexDr {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  font-size: var(--text);
  font-weight: 400;
  font-family: Inter;
  color: #fff;
  margin-left: 6px;
}

.imgMainDiv:hover + div .content .title{
  color: #ff0000;
}

.mainDiv {
  transition: all 0.3s ease-out;
  min-height: 181px;

}

.mainDiv:has(.imgDiv:hover) {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0px 2px 5px 0px #171717;
}

.mainDiv:has(.title:hover) {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0px 2px 5px 0px #171717;
}

@media only screen and (max-width: 431px) {
  .imgDiv {
    height: auto;
  }

  .subTitle {
    margin-top: 13px;
  }

  .footerDiv {
    width: 100%;
    justify-content: space-between;
    margin-top: 5px;
  }
  .footerDivP {
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
  }
}
