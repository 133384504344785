.centreContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
 .col{
  display: flex;
  flex-direction: row;
  width:100%;

 }
 .col5{
  display: flex;
  flex-direction: row;
  width:550px;

 }

 .ratingDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ratingHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  width: 100%;
  margin-bottom: 23px;
}
.ratingParam {
  min-width: 30%;
}
.mainDiv{
  width: 100%;
}

.rating {
  width: 33%;
}

.ratingStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
 
  width: 100%;
  margin-bottom: 10px;
}
.ratingSubDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}
 .col3{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
 }
 .images {
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-wrap: wrap;
}

 .col4{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-left: 50px;
 }
 .col2{
  display: flex;
  flex-direction: row;
  gap:5px;


 }
 .row{
  display: flex;
  flex-direction: column;
  margin-left:10px;
 

 }
 .row2{
  display: flex;
  flex-direction: column;
  margin-left:10px;
  width: 90%;
 }
.wrapper{
  width: 100%;
}
 
 .row3{
  display: flex;
  flex-direction: column;
  margin-left:10px;
 }
 .seventy{
 
    display: flex;
    flex-direction: column;
    margin-left:10px;
    width: 60%;
 }
 .chips{
  display: flex;
  flex-direction: row;
 gap:10px
}

.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.albumsSubDiv {
  width: auto;
}


@media only screen and (max-width: 431px){
  .centreContent {
    width: 100%;
  }
  .col{
    width:100%;
  }
  .col2{
    width:100%;
  }
  .row{
    width:100%;
  }
}
@media only screen and (max-width: 834px) {
  .ratingStyle {
    justify-content: space-between;
  }

  .ratingHead {
    gap: 0px;
    margin-top: 20px;
  }

  .ratingSubDiv {
    margin-bottom: 0;
  }
  .albumsSubDiv {
    width: 46%;
  }

}