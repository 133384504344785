.navBar {
  width: 100%;
  height: auto;
  background-color: #000;
  padding-bottom: 30px;
  min-height: 62vh;
  padding-top: 20px;
}

.breadCrumbs {
  width: 100%;
  height: auto;
  padding-top: 20px;
}

.musicPlayer {
  bottom: 0;
  position: fixed;
  padding: 5px;
  width: 100%;
}
.container{
  min-height: 100vh;
  background-color: #000;
}