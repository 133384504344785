.eventSlidesDiv {
  width: 100%;
  position: relative;
}

.mgb {
  margin-bottom: 40px;
}

.subText {
  text-align: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
}

.dltButton{
  position: absolute;
  top: -100%;
  left: 0;
  width: 40px;
  height: 60px;
  background-color: #ff0000;
  transition: all .3s ease .2s;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventSlidesDiv:hover .dltButton{
  top: 0;
}

.DeleteIcon{
  font-size: 22px !important;
  color: #fff;
}

@media only screen and (max-width: 900px) {
  .eventSlidesDiv {
    width: 95%;
  }

  .dltButton{
    top: 0;
  }
}
