@import url(../../../../css/variables.css);

.mainBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: #343232;
  border-radius: 24px;
  box-shadow: 24px;
  max-height: 350px;
  padding: 20px;
  overflow: hidden;
  min-width: 300px;
}

.subBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #343232;
  border-radius: 24px;
  box-shadow: 24px;
  max-height: 350px;
  padding: 20px;
  overflow: hidden;
}

.modalDiv {
  z-index: 99999999999 !important;
}

.playListDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.playlistHead {
  color: #fff;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 400;
}

.plusIcon {
  color: #ff0000;
  font-size: 26px !important;
}

.noPlaylist {
  color: #8c8c8c;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}

.playlistGroupDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;
}

.playlistGroupDiv::-webkit-scrollbar-track {
  background: transparent;
}

.mgTop {
  margin-top: 24px;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
}

@media only screen and (max-width: 431px) {
  .mainBox {
    width: 90%;
  }

  .subBox {
    width: 90%;
  }
}
