@import url(../../css/variables.css);

.artist {
  display: flex;
  align-items: center;
  font-size: var(--smallText);
  font-family: Inter;
  font-weight: 500;
  color: #c8c8c8;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.divMain {
  width: 100%;
}

.artistImage {
  width: 100%;
  height: auto;
}

.threedots{
  font-size: 18px !important;
  color: #ff0000;
}

.description {
  font-size: var(--body);
  font-weight: 400;
  color: #bbb;
  font-family: Inter;
  font-style: normal;
}

.mgb {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.eventTag {
  margin-bottom: 15px;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
}

.mgb40{
  margin-bottom: 40px;

}

.sortBy {
  width: 200px;
}
.ascDescIcon {
  font-size: 18px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.sortDiv {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.ascDescIcon1 {
  font-size: 18px !important;
  color: #ff0000;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1:hover {
  color: #fff;
}

.ascDescIcon:hover {
  color: #ff0000;
}

.flexDr {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
}

.mgb10 {
  margin-bottom: 20px;
}
.flexDrR {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.songListDiv {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
}

.playlistsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.playlistsSubDiv {
  width: auto;
}

.cardWidth {
  width: auto;
}

.EventCardWidth{
  width: 47%;
}

@media only screen and (max-width: 431px) {
  .cardWidth {
    width: 47%;
  }

.EventCardWidth{
  width: 100%;
}

  .playlistsSubDiv {
    width: 46%;
  }
  .sortBy {
    width: 80%;
  }
}
@media only screen and (max-width: 360px) {
  .cardWidth {
    width: 46%;
  }

}