@import url(../../css/variables.css);

.linear {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(transparent, #000);
}

.imageDiv {
  position: relative;
  display: flex;
  overflow: hidden;
}

.image {
  position: relative;
  display: flex;
  overflow: hidden;
}

.image img {
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.cardContainer {
  height: auto;
  display: flex;
  flex-direction: column;
}

.image:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.cardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.title {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-top: 10px;
  font-weight: 700;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
