@import url(../../css/variables.css);

.text{
font-size: var(--text);
font-family: Inter;
font-weight: 500;
margin-top: 30px;
margin-bottom: 40px;
color: #fff;
cursor: pointer;
text-align: center;
transition: all .6s ease-out;
border :#fff solid 1px;
padding:5px;
width: 135px;
border-radius: 5px;
}
.border{
    width: 100%;
    display: flex;
    justify-content: center;
}
.text:hover{
    color: #000000;
    background-color: #fff;
}