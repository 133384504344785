@import url(../../css/variables.css);

.heading {
    color: #ff0000;
    font-size: var(--subTitle);
    font-family: Inter;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .subHeading {
    color: #fff;
    font-size: var(--heading);
    font-family: Inter;
    font-weight: 700;
    margin-bottom: 23px;
  }

  .contentBody {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 280px;
  }

  .country {
    color: #fff;
    font-size: var(--body);
    font-family: Inter;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    text-wrap: wrap;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }

  .country:hover{
    color: #ff0000;
  }
  
  .countryFlag {
    width: 20px;
    height: 14px;
    margin-right: 10px;
  }

  
@media only screen and (max-width: 834px) {

    .heading{
      margin-bottom: 0px;
    }
  
    .subHeading {
    margin-top: 20px;
    }
  }
  