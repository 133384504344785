@import url(../../css/variables.css);

.artist {
  display: flex;
  align-items: center;
  font-size: var(--smallText);
  font-family: Inter;
  font-weight: 500;
  color: #c8c8c8;
}

.mgb {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.mgb40 {
  margin-bottom: 40px;
}

.sortBy {
  width: 200px;
}
.ascDescIcon {
  font-size: 18px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.sortDiv {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.ascDescIcon1 {
  font-size: 18px !important;
  color: #ff0000;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1:hover {
  color: #fff;
}

.ascDescIcon:hover {
  color: #ff0000;
}

.flexDr {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
}

.mgb10 {
  margin-bottom: 20px;
}
.flexDrR {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.songListDiv {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
}

.albumsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.albumsSubDiv {
  width: auto;
}

.cardWidth {
  width: auto;
}

.allMusicMainDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flexDr1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65%;
  align-items: flex-start;
}

.allMusicSubDiv {
  display: flex;
  width: 270px;
  position: relative;
  height: 37.13px;
}

.allMusicIconButton {
  right: 4px !important;
  background-color: transparent;
  position: absolute !important;
  top: -2px;
}

.allMusicSearchIcon {
  color: #ff0000;
  font-size: 26px !important;
}

@media only screen and (max-width: 900px) {
  .flexDr1 {
    width: 60%;
  }

}

@media only screen and (max-width: 700px) {
  .allMusicMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .flexDr1 {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .sortDiv{
    width: 100%;
  }

  .allMusicSubDiv {
    width: 100%;
  }
  .sortBy {
    width: 80%;
  }
}

@media only screen and (max-width: 431px) {
  .cardWidth {
    width: 47%;
  }
  .albumsSubDiv {
    width: 46%;
  }
  .sortBy {
    width: 80%;
  }
}
@media only screen and (max-width: 360px) {
  .cardWidth {
    width: 46%;
  }
}
