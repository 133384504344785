@import url(../../../css/variables.css);

.itineraryBox {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  border-bottom: 1px solid #ff0000;
  cursor: pointer;
}

.itinerarySubBox {
  display: flex;
  flex-direction: row;
}

.box {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentDiv {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.title {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
}

.subTitle {
  font-size: var(--text);
  font-weight: 400;
  font-family: Inter-Regular;
  color: #909090;
  margin-top: 6px;
}

.flexDr {
  display: flex;
  align-items: center;
}

.icon {
  color: #8c8c8c;
  font-size: 24px !important;
  cursor: pointer;
}

@media only screen and (max-width: 431px) {

  .contentDiv {
    width: 60%;
  }

  .title {
    font-size: var(--text);
  }

  .subTitle {
    font-size: var(--smallText);
    margin-top: 3px;
  }
}
