@import url(../../css/variables.css);

.commentsMainDiv {
  width: 100%;
}

.commentsSubDiv {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

.commentsGrid {
  display: flex;
  justify-content: center;
}

.flexDr{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.commentsDiv {
  min-width: 45px;
  min-height: 45px;
  width: 45px !important;
  height: 45px !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commentsAvatar {
  min-width: 45px;
  min-height: 45px;
  width: 45px !important;
  height: 45px !important;
  position: relative;
  border-radius: 50px;
  object-fit: cover;
}

.classifiedChatCardAvatar {
  min-width: 45px;
  min-height: 45px;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  background-color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classifiedChatCardUsername {
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

.commentsGrid2 {
  display: flex;
  flex-direction: column;
}

.commentsDiv2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.commentsUsername {
  font-size: var(--heading);
  font-weight: 600;
  font-family: Inter;
  color: #fff;
}

.commentsText {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  margin-top: 5px;
}

.commentsLikeDiv {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.commentsLike {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.commentsDelete{
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

.commentsLikeCount {
  color: #fff;
  margin-left: 5px;
  font-size: var(--text);
  font-family: Inter;
}

.commentsReply {
  color: #fff;
  font-weight: 600;
  font-size: var(--text);
  font-family: Inter;
  margin-left: 10px;
  cursor: pointer;
}

.mgb15 {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.commentsRepliesDiv {
  margin-left: 66px;
  margin-top: 15px;
}
