@import url(../../css/variables.css);

.labelText {
  color: #fff;
  font-family: Inter;
  font-weight: 700;
  position: relative;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.labelText::before {
  background: #ff0000;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  bottom: -10px;
  width: 50px;
}

.labelTextCenter {
  color: #fff;
  font-family: Inter;
  font-weight: 700;
  position: relative;
  margin-bottom: 40px;
  text-transform: capitalize;
  text-align: center;
  display: flex;
  justify-content: center;
}

.center {
  background: #ff0000;
  content: "";
  height: 3px;
  position: absolute;
  width: 50px;
  bottom: -8px;
}
