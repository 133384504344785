.comments{
  display: flex;
  background-color: #000 !important;
  padding: 20px;
  transition: all .3s ease .2s;
  max-height: 60vh;
  overflow: auto;
  min-width: 200px;
  max-width: 100%;
}
@media only screen and (max-width: 500px) {
.comments{
  position:absolute;
  width: 100%;
  z-index: 9999999;
  height: 60vh;
}
}