@import url(../../css/variables.css);

.cardParent {
  overflow: hidden;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 10px;
}

.genreTitle {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  font-size: var(--subTitle);
  text-transform: capitalize;
}

.threeDots {
  font-size: 20px !important;
  color: #000;
  cursor: pointer;
}

.songListItemParent {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
}

.songListItemText {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 5px;
}

.albumName {
  flex: 1;
  font-size: var(--h1);
  text-transform: capitalize;
}

.header {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  padding-left: 35px;
  padding-right: 35px;
}

.gridContainers {
  min-width: 250px;
  min-height: 130px;
}

.showXs {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90% !important;
  margin: auto;
  flex-basis: 90% !important;
}

.hideXs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 431px) {
  .songListItemParent {
    width: 90%;
    margin: auto;
  }

  .header {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
    margin: auto;
  }

  .gridContainers {
    display: flex;
    flex-direction: column;
    gap: 10px !important;
    margin-top: 10px;
  }

  .showXs {
    display: flex;
  }

  .hideXs {
    display: none;
  }

  .albumName {
    display: none;
  }
}
