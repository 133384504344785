.allCategorySubDiv {
  display: flex;
  width: 270px;
  position: relative;
  height: 37.13px;
}

.allCategoryMainDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.allCategoryIconButton {
  right: 4px !important;
  background-color: transparent;
  position: absolute !important;
  top: -2px;
}

.allCategorySearchIcon {
  color: #ff0000;
  font-size: 26px !important;
}
.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.mgb{
  margin-bottom: 40px;
}
@media only screen and (max-width: 431px) {
  .allCategorySubDiv {
    width: 100%;
    margin-top: -10px;
  }

  .allCategoryMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
}
