@media only screen and (min-width: 1200px) {
  .yarl__slide_image {
    height: calc(100vh-184px);
    width: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .yarl__slide_image {
    width: min(700px, 100%);
    height: auto;
  }
}

@media only screen and (max-width: 950px) {
  .yarl__slide_image {
    width: min(550px, 100%);
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .yarl__slide_image {
    width: min(400px, 100%);
    height: auto;
  }
}

@media only screen and (max-width: 650px) {
  .yarl__slide_image {
    width: min(300px, 100%);
    height: auto;
  }
}

@media only screen and (max-width: 500px) {
  .yarl__toolbar {
    top: 35px !important;
  }
  .yarl__slide_title {
    max-width: 100% !important;
  }
  .yarl__slide_title_container {
    height: 95px !important;
  }
}
