@import url(../../css/variables.css);

.chipsArray {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  margin-bottom: 27px;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.list {
  color: #fff;
}

.listStyle {
  display: flex;
  gap: 10px;
  flex-direction: column;
  list-style: disc !important;
}

.listText {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: var(--body);
  list-style: disc !important;
  margin-left: 15px;
}

.inboxDiv {
  display: flex;
  justify-content: center;
}

.classifiedCardUsername {
  font-size: var(--heading);
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.tabsArrow {
  font-size: 18px !important;
  color: #fff;
}

.inboxSubDiv {
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 20px;
}

.newClassifiedSubDiv {
  display: flex;
  width: 80%;
  flex-direction: column;
  gap: 20px;
}

.classifiedChatboxSubDiv {
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 70px;
}

.labelDiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.label {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: var(--heading);
}
.label1 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: var(--heading);
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label2 {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.label3Div {
  width: 25%;
  display: flex;
  justify-content: flex-end;
}
.label3 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: var(--heading);
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.classifiedCardAvatar {
  min-width: 30px;
  min-height: 30px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  object-fit: cover;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrapper1 {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.subWrapper {
  width: 49%;
}

.image {
  width: auto;
  height: 100px;
}

.subWrapperDiv {
  width: 100%;
}

.commentsSubDiv {
  display: flex;
  width: 100%;
  position: relative;
}

.textField {
  position: fixed;
  bottom: 0px;
  padding-bottom: 10px;
  width: 86%;
  background-color: #000;
}

.sortDiv {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}
.sortBy {
  width: 200px;
}
.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}
.ascDescIcon {
  font-size: 18px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1 {
  font-size: 18px !important;
  color: #ff0000;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1:hover {
  color: #fff;
}

.ascDescIcon:hover {
  color: #ff0000;
}

.category {
  cursor: pointer;
}

.category:hover {
  text-decoration: underline;
}

.authouName:hover {
  text-decoration: underline;
}

.categoryName {
  color: #c8c8c8;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
  margin-bottom: 10px;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.flexDrR {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.flexDir {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.subTitle {
  color: #DFDFDF;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.firstContainer {
  width: 100%;
}

.secondContainer {
  width: 100%;
}

.submitImgSm {
  width: 100%;
  height: auto;
  cursor: pointer;
  display: none;
}

.submitImg {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.fullWidthContainer {
  width: 100%;
}

.fullWidthContainerFd {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.subText {
  text-align: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
}

.mgb {
  margin-bottom: 40px;
}

.calendar {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  margin-top: 40px;
}

.filter {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calendarHead {
  color: #fff;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 500;
}
.date {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  margin-bottom: 10px;
}

.to {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.flexDr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.classifiedChatCardAvatar {
  min-width: 30px;
  min-height: 30px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  background-color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classifiedChatCardUsername {
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

.flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.add {
  color: #fff;
  font-size: 30px !important;
  cursor: pointer;
}
.inbox {
  color: #fff;
  font-size: 28px !important;
  cursor: pointer;
}

.addMd {
  color: #fff;
  font-size: 24px !important;
  cursor: pointer;
}

.inboxMd {
  color: #fff;
  font-size: 22px !important;
  cursor: pointer;
}

.iconsSm {
  display: none;
  flex-direction: row;
  gap: 13px;
  align-items: center;
}
.iconsMd {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.iconsSmDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.calendarSm {
  font-size: 20px !important;
  color: #fff;
  cursor: pointer;
}

.plus {
  font-size: 14px !important;
  color: #fff;
  font-weight: 400;
  margin-right: 10px;
}

.filterSm {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.upDown {
  font-size: 14px !important;
  color: #fff;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.topBox {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 30px;
}

.topBoxTag {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
}

.topImgContainer {
  display: flex;
  flex-direction: row !important;
  gap: 6px;
  align-items: center;
}

.artist {
  display: flex;
  align-items: center;
  font-size: var(--smallText);
  font-family: Inter;
  font-weight: 500;
  color: #c8c8c8;
}

.top {
  color: #fff;
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.top:hover {
  color: #ff0000;
}

.redHead {
  font-size: var(--subTitle);
  font-weight: 700;
  font-family: Inter;
  color: red;
  margin-bottom: 20px;
}

.poster {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.body {
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}

.customButton {
  background-color: #0366ff !important;
  font-size: var(--body) !important;
  color: #fff !important;
  border-radius: 0 !important;
  text-transform: none !important;
  width: 80%;
  height: 36.5px !important;
}

.ratingMainDiv {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.ratingSubDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ratingDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ratingStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: var(--body);
  font-weight: 600;
  font-family: Inter;
  width: 100%;
  margin-bottom: 10px;
}

.eventSlidesDiv {
  width: 100%;
}

.ratingHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  width: 100%;
  margin-bottom: 23px;
}

.ratingParam {
  min-width: 30%;
}

.rating {
  width: 33%;
}

.locationIcon {
  font-size: 20px !important;
  color: #ff0000;
}

.imgIcon {
  min-height: 200px;
  height: auto;
  width: 100%;
  margin-top: 10px;
}

.headingStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  gap: 6px;
}

.headingStyleMgb {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  gap: 6px;
  margin-bottom: 20px;
}

.contentStyle {
  color: #fff;
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.gridGeneral {
  padding-right: 30px;
}

.gridGeneralL {
  border-left: 1px solid #ff0000;
  padding-left: 20px;
  display: flex;
  flex-direction: column !important;
  gap: 20px;
}

.spacingLR {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column !important;
  gap: 20px;
}

.timeFontIcon {
  font-size: 20px !important;
  color: #ff0000;
}

.contentDiv {
  width: 100%;
  margin-bottom: 40px;
}

.content {
  font-size: var(--text);
  margin-top: 2px;
  color: #ff0000;
}

.overallRatingDiv {
  width: 100%;
  background-color: #1a1a1a;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  flex-direction: column;
}

.overallHeading {
  font-size: var(--heading);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  margin-bottom: 20px;
}

.gridBorderRight {
  border-right: 1px solid #fff;
}

.overallRatingSubDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: baseline;
  gap: 20px;
}

.overallRatingHead {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: #fff;
}

.overallRating {
  font-size: var(--h1);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

.calendarImage {
  height: 30px;
  width: auto;
  cursor: pointer;
}

.flexGap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.flexButton {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 40%;
  align-items: center;
}

.classifiedCardsList {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.smBanner {
  display: none;
}

.BannerAds {
  width: 100%;
  height: auto;
  margin-top: 30px;
  max-width: 100%;
  overflow: hidden;
}

.BannerAds ins{
  width: 100% !important;
  max-width: 100%;
  height: auto !important;
}

@media only screen and (max-width: 834px) {
  .BannerAds {
    margin-bottom: 30px;
  }

  .smBanner {
    display: flex;
  }

  .container {
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  .textField{
    width: 93%;
  }

  .label2{
    width: 100%;
  }

  .label1, .label3{
    width: 60%;
    flex-direction: row;
    gap: 8px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }

  .subWrapper {
    width: 100%;
  }

  .inboxSubDiv {
    width: 100%;
  }

  .newClassifiedSubDiv {
    width: 100%;
  }
  .classifiedChatboxSubDiv {
    width: 100%;
  }

  .iconsMd {
    display: none;
  }
  .flexButton {
    width: 100%;
  }

  .eventSlidesDiv {
    width: 95%;
  }

  .ratingStyle {
    justify-content: space-between;
  }

  .ratingHead {
    gap: 0px;
    margin-top: 20px;
  }

  .ratingSubDiv {
    margin-bottom: 0;
  }

  .spacingLR {
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .gridLeftPadding {
    padding-left: 0px;
  }

  .gridGeneral {
    padding-right: 0;
  }

  .gridGeneralL {
    border-left: 0;
    padding-left: 0;
    gap: 30px;
  }

  .poster {
    width: 100%;
    height: auto;
  }

  .iconsSm {
    display: flex;
  }

  .submitImg {
    display: none;
  }

  .submitImgSm {
    display: flex;
  }

  .firstContainer {
    width: 100%;
  }
  .sortBy {
    width: 80px;
  }

  .secondContainer {
    display: none;
  }

  .flexDr {
    justify-content: space-between;
  }

  .filter {
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .calendar {
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .buttonDiv {
    flex-direction: column;
  }

  .customButton {
    width: 85% !important;
  }

  .heading {
    margin-bottom: 0px;
  }

  .subHeading {
    margin-top: 20px;
  }

  .gridBorderRight {
    border-right: 0;
  }
}
