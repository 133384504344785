@import url(../../css/variables.css);

.arrowRight{
    color: #7C7979;
    font-size: 14px !important;
}

.links{
    font-size: var(--text) !important;
    font-family: Inter !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}