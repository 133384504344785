@import url(../../css/variables.css);

.mainContainer {
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  
}

.Login {
  color: #fff;
  font-size: 80px;
  font-family: "Londrina Outline", sans-serif;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.box {
  height: auto;
  width: 75%;
  border-radius: 101px;
  background-color: #242424;
  padding: 50px 100px;
}

.heading {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: var(--heading);
 
}
.head {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: var(--heading);
  text-align: center;
 
}
.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap:10px;
  justify-content: center;
  margin-top: 20px;
}
.flexDr3{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

}

.socialIcon {
  font-size: 30px !important;
  color: #fff;
  cursor: pointer;
}

.socialImage {
  height: 30px;
  width: auto;
  cursor: pointer;
}

.orContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.hr {
  border: 1px solid #ff0000;
  width: 47%;
}

.orText {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: var(--body);
  width: 6%;
  text-align: center;
}

.mgt10 {
  margin-top: 10px;
}
.required{
  color: #ff0000;
}
.mgt20 {
  margin-top: 20px;
}

.flexDr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fields {
  display: flex;
  flex-direction: row;
  gap: 4%;
}

.fields3 {
  display: flex;
  flex-direction: row;
  gap: 4%;
}

.subFields {
  width: 48%;
}

.miniFields {
  width: 100%;
}

.miniFields1 {
  width: 30%;
  display: flex;
  align-items: flex-end;
}
.imageContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.error{
  font-size: var(--smallText);
  color: #ff0000;
}
.imagePicker {
  width: 200px;
  height: 200px;
  border-radius: 52%;
  background-color: #fff;
  cursor:pointer;
  margin-bottom: 20px;
}

.imageFlexDr {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  object-fit: cover;
}

.imageText {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: var(--body);
  margin-left: 10px;
}

@media only screen and (max-width: 431px) {
  .box {
    height: 100%;
    width: 100%;
    border-radius: 51px;
    padding: 50px 30px;
  }

  .hr {
    width: 42%;
  }

  .orText {
    width: 16%;
  }

 

  .fields3{
    flex-direction: column;
    gap: 20px;
  }

  .miniFields {
    width: 100%;
  }
  
  .miniFields1 {
    width: 100%;
  }
}
