/* Custom Dark Theme for CKEditor 5 */
.ck-editor__main,
.ck .ck-content {
    min-height: 100px;
}

.ck-editor__main {
  background-color: #333 !important;
  color: #fff !important;
}

.ck .ck-content {
  background-color: #333 !important;
  color: #fff !important;
}

.ck .ck-toolbar {
  background-color: #444 !important;
  border-bottom: 1px solid #555 !important;
}

.ck .ck-toolbar__item {
  color: #fff !important;
}

.ck .ck-button {
  color: #fff !important;
}

.ck .ck-button.ck-on {
  background-color: #555 !important;
}

.ck .ck-dropdown__panel {
  background-color: #333 !important;
  color: #fff !important;
  border: 1px solid #555 !important;
}

.ck .ck-list__item {
  background-color: #333 !important;
  color: #fff !important;
}

.ck .ck-list__item:hover {
  background-color: #000 !important;
}

.ck .ck-list__item .ck-button__label {
  color: #fff !important;
}

.ck .ck-list__item .ck-button__label:hover {
  color: #fff !important;
}

.ck .ck-dropdown__panel .ck-list__item .ck-button__label {
  color: #fff !important;
}

.ck .ck-dropdown__panel .ck-list__item .ck-button__label:hover {
  color: #fff !important;
}

.ck .ck-dropdown__panel .ck-list__item.ck-on {
  background-color: #555 !important;
}

/* Hover effect for list items */
.ck .ck-list__item:hover,
.ck .ck-list__item .ck-button__label:hover,
.ck .ck-dropdown__panel .ck-list__item:hover,
.ck .ck-dropdown__panel .ck-list__item .ck-button__label:hover {
  background-color: #444 !important;
  color: #fff !important;
}

/* Hover effect for toolbar items */
.ck .ck-toolbar__item:hover,
.ck .ck-toolbar__item .ck-button__label:hover {
  background-color: #555 !important;
  color: #fff !important;
}

/* Active list item styles */
.ck .ck-dropdown__panel .ck-list__item.ck-on {
  background-color: #555 !important;
}

.ck .ck-dropdown__panel .ck-list__item:hover .ck-button {
  background-color: #444 !important;
  color: #fff !important;
}

.ck .ck-dropdown__panel .ck-list__item:hover .ck-button__label {
  color: #fff !important;
}

/* Active list item styles */
.ck.ck-button.ck-off:hover {
  background-color: #555 !important;
}

.ck-content img{
  max-width: 100%;
}

.ck-content a {
  color: yellow !important;
}

.ck-content a span {
  color: yellow !important;
}