.dash {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageText {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: var(--body);
  margin-left: 10px;
}
