@import url(../../css/variables.css);

.classifiedCardMainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
}

.clickableDiv {
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
}
.unClickableDiv {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.classifiedChatCardAvatar {
  min-width: 45px;
  min-height: 45px;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  background-color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classifiedChatCardUsername {
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

.closeIcon {
  font-size: 20px !important;
  color: #fff;
}

.flexDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
}

.flexCenter {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.flexCenter5 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.unClickableFlexCenter5 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.classifiedCardAvatar {
  min-width: 45px;
  min-height: 45px;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  object-fit: cover;
}

.classifiedCardDiv2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
}

.classifiedCardUsername {
  font-size: var(--heading);
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.classifiedCardText {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #ff0000;
}

.classifiedCardType {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #a4a4a4;
}

.classifiedCardBody {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  max-width: 100%;
}

.classifiedCardBody figure img {
  max-width: 100%;
}

.classifiedCardTitle {
  font-size: var(--body);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
}

.imageDiv {
  width: 100%;
  height: auto;
  position: relative;
}

.image {
  width: 100%;
  height: auto;
}

.expand {
  font-size: 22px !important;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.message {
  font-size: 20px !important;
  color: #ff0000;
}
