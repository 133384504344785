@import url(../../css/variables.css);

ul,
ol {
  margin-left: 15px;
}

.heading {
  color: #ff0000;
  font-size: var(--subTitle);
  font-family: Inter;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 30px;
}
.sortDiv {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}
.sortBy {
  width: 200px;
}
.ascDescIcon {
  font-size: 18px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.albumsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.albumsSubDiv {
  width: auto;
}

.ascDescIcon1 {
  font-size: 18px !important;
  color: #ff0000;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1:hover {
  color: #fff;
}

.ascDescIcon:hover {
  color: #ff0000;
}
.mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.subHeading {
  color: #fff;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 700;
  margin-bottom: 23px;
}
.title {
  cursor: pointer;
}
.title:hover {
  color: #ff0000;
  transition: all 0.3s ease-in;
}

.subTitle {
  font-size: var(--text);
  color: #ffffff;
  margin-top: 2px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
}
.mgt30 {
  margin-top: 30px;
}
.subTextCard {
  font-size: var(--body);
  color: #ffffff;
  font-weight: 500;
  font-family: Inter;
  text-align: center;
}

.mgb {
  margin-bottom: 40px;
}

.classifiedsDiv {
  width: 370.6px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.noData {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
  text-align: center;
}

.classifiedTitle {
  text-align: center;
  color: #fff;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 600;
}

.imgd {
  width: 100%;
  height: 360px;
  position: relative;
  overflow: hidden;
}

.imgDiv {
  filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.classifiedImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
}

.classifiedSubText {
  text-align: center;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 600;
}

.poster {
  width: 100%;
  min-height: 100px;
  height: auto;
}

.body {
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  color: #fff;
}

.body table, .body tbody, .body tr, .body td{
  max-width: 100%;
} 

.body a{
  color: yellow !important;
  }

.body a span{
  color: yellow !important;
  }

.body p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.body img {
  max-width: 100%;
  height: auto;
}

.time {
  font-size: var(--text);
  font-family: Inter;
  font-weight: 400;
  color: #fff;
}

.chipsArray {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  margin-top: 27px;
  align-items: center;
}

.showDate {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 27px;
  align-items: center;
}

.shareTooltip {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 27px;
  align-items: center;
}

.tabDiv {
  position: relative;
  margin-top: 50px;
}

.leftShadow {
  height: 48px;
  width: 10px;
  position: absolute;
  left: -35px;
  background: linear-gradient(
    to right,
    #fff 2%,
    rgba(255, 255, 255, 0.3) 40%,
    transparent
  );
}

.rightShadow {
  height: 48px;
  width: 10px;
  position: absolute;
  right: -35px;
  top: 0;
  background: linear-gradient(
    to left,
    #fff 2%,
    rgba(255, 255, 255, 0.3) 40%,
    transparent
  );
}

.eventTag {
  margin-bottom: 30px;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
}

.headingStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  gap: 6px;
}

.headingStyleMgb {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  gap: 6px;
  margin-bottom: 20px;
}

.contentStyle {
  color: #fff;
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  margin-top: 10px;
}

.contentStyle table, .contentStyle tbody, .contentStyle tr, .contentStyle td{
  max-width: 100%;
} 

.contentStyle a{
  color: yellow !important;
  }
  
.contentStyle a span{
  color: yellow !important;
  }

.contentStyle p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.contentStyle img {
  max-width: 100%;
  height: auto;
}

.flexDR {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.flexDr1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65%;
}
.cardLoader {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.minWidth {
  min-width: 560px;
}
.events {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}
.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.flexDrR1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.foodText {
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: var(--body);
  margin-bottom: 5px;
  margin-top: 10px;
}

.circleIcon {
  font-size: 8px !important;
  margin-top: 4px;
  margin-right: 8px;
}

.foodTitle {
  font-weight: 600;
  font-family: Inter;
  min-width: 200px;
}

.foodDescription {
  font-weight: 400;
  font-family: Inter;
}

.points {
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  margin-bottom: 5px;
}

.heads {
  color: #fff;
  font-size: var(--body);
  font-weight: 600;
  font-family: Inter;
  margin-bottom: 15px;
}

.reviewDiv {
  width: 100%;
  height: auto;
}

.gridPadding {
  padding-right: 40px;
}

.overallRatingDiv {
  width: 100%;
  background-color: #1a1a1a;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 137px;
  flex-direction: column;
}

.overallHeading {
  font-size: var(--heading);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  margin-bottom: 20px;
}

.gridBorderRight {
  border-right: 1px solid #fff;
}

.overallRatingSubDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: baseline;
  gap: 20px;
}

.overallRatingHead {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: #fff;
}

.overallRating {
  font-size: var(--h1);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

.reviewTitle {
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
  margin-top: 40px;
  margin-bottom: 20px;
}

.review {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: #fff;
  margin-bottom: 40px;
}


.review table, .review tbody, .review tr, .review td{
  max-width: 100%;
} 

.review a{
  color: yellow !important;
  }
  
.review a span{
  color: yellow !important;
  }

.review p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.review img {
  max-width: 100%;
  height: auto;
}

.reviewAuthor {
  font-size: var(--heading);
  font-weight: 600;
  font-family: Inter;
  color: #e4e4e4;
  margin-bottom: 10px;
  font-style: italic;
}

.reviewPublished {
  font-size: var(--body);
  font-weight: 600;
  font-family: Inter;
  color: #e4e4e4;
  margin-bottom: 10px;
}

.grid2Div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reviewHead {
  display: flex;
  flex-direction: row;
  font-size: var(--body);
  color: #fff;
  font-weight: 400;
  font-family: Inter;
  gap: 20px;
  align-items: center;
  border-right: 1px solid #fff;
  padding-right: 10px;
}
.reviewHead1 {
  display: flex;
  flex-direction: row;
  font-size: var(--body);
  color: #fff;
  font-weight: 400;
  font-family: Inter;
  gap: 20px;
  align-items: center;
}

.ratingMainDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.ratingSubDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ratingDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ratingStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: var(--body);
  font-weight: 600;
  font-family: Inter;
  width: 100%;
  margin-bottom: 10px;
}

.ratingHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  width: 100%;
  margin-bottom: 23px;
}

.ratingParam {
  min-width: 30%;
}

.rating {
  width: 33%;
}

.multiReviewDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.reviewFont {
  font-size: 30px !important;
}

.reviewFontMgl {
  font-size: 30px !important;
  margin-left: 10px;
}

.tabPanel {
  margin-top: 40px;
  margin-bottom: 40px;
}

.divMain {
  width: 100%;
}

.gridGeneral {
  border-right: 1px solid #ff0000;
  padding-right: 20px;
}
.gridGeneralL {
  border-left: 1px solid #ff0000;
  padding-left: 20px;
}

.timeFontIcon {
  font-size: 20px !important;
}

.calendarFontIcon {
  font-size: 18px !important;
}

.currencyFontIcon {
  font-size: 24px !important;
}

.flightFontIcon {
  font-size: 22px !important;
}

.imgIcon {
  height: 22px;
  width: auto;
}

.gridLeftPadding {
  padding-left: 20px;
}

.hotelIcon {
  height: 20px;
  width: auto;
}

.spacingLR {
  padding-left: 20px;
  padding-right: 20px;
}

.paddingL {
  padding-left: 10px;
}

.paddingR {
  padding-right: 10px;
}

.imgSocial {
  width: 43px;
  height: 43px;
  cursor: pointer;
}

.artistTitle {
  display: flex;
  align-items: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 500;
  color: #c8c8c8;
}

.capsuleMainDiv {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.worldCarnivalMainDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.capsuleR {
  width: 83px;
  border-radius: 0px 21px 21px 0px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
}

.capsuleL {
  width: 83px;
  border-radius: 21px 0px 0px 21px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
}

.locationIcon {
  color: #ff0000;
  font-size: 14px !important;
}

.calendarIcon {
  color: #ff0000;
  font-size: 12px !important;
}

.flexDrC {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.subText {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
  cursor: pointer;
}

.subText:hover {
  color: #ff0000;
  transition: all 0.3s ease-in;
}

.ratingCarnival {
  display: flex;
  align-items: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 400;
  color: #fff;
}

.star {
  color: yellow;
  font-size: 22px;
  margin-left: 2px;
  margin-right: 2px;
}

.allCarnivalSubDiv {
  display: flex;
  width: 270px;
  position: relative;
  height: 37.13px;
}

.allCarnivalMainDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.allCarnivalIconButton {
  right: 4px !important;
  background-color: transparent;
  position: absolute !important;
  top: -2px;
}

.allCarnivalSearchIcon {
  color: #ff0000;
  font-size: 26px !important;
}

.tabs {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.tabsMain {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tabsText {
  display: flex;
  font-size: var(--subTitle);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tabsArrow {
  font-size: 18px !important;
  color: #fff;
}

.col3 {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.row2 {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 90%;
}

.textTransform {
  transform-origin: center;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(270deg);
  height: auto;
}

@media only screen and (max-width: 900px) {
  .flexDr1 {
    width: 60%;
  }
  .ratingStyle {
    justify-content: space-between;
  }

  .ratingMainDiv {
    margin-top: 40px;
  }

  .ratingHead {
    gap: 0px;
    margin-top: 20px;
  }

  .ratingSubDiv {
    margin-bottom: 0;
  }

  .spacingLR {
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .gridLeftPadding {
    padding-left: 0px;
  }

  .gridGeneral {
    border-right: 0;
    padding-right: 0;
  }

  .gridGeneralL {
    border-left: 0;
    padding-left: 0;
    gap: 30px;
  }

  .heading {
    margin-bottom: 0px;
  }
  .cardLoader {
    gap: 0px;
  }
  .subHeading {
    margin-top: 20px;
  }

  .gridBorderRight {
    border-right: 0;
  }
  .sortBy {
    width: 150px;
  }
}

@media only screen and (max-width: 700px) {
  .spacingLR {
    padding-left: 0;
    padding-right: 0;
  }

  .gridLeftPadding {
    padding-left: 0px;
  }

  .gridGeneral {
    border-right: 0;
    padding-right: 0;
  }
  .sortBy {
    width: 80%;
  }
  .gridGeneralL {
    border-left: 0;
    padding-left: 0;
  }

  .heading {
    margin-bottom: 0px;
  }

  .subHeading {
    margin-top: 20px;
  }

  .classifiedsDiv {
    width: 100%;
  }

  .poster {
    width: 100%;
    height: auto;
  }

  .gridPadding {
    padding-right: 0px;
  }

  .gridBorderRight {
    border-right: 0;
  }

  .ratingStyle {
    justify-content: space-between;
  }

  .ratingHead {
    gap: 0px;
    margin-top: 20px;
  }

  .reviewPublished {
    margin-bottom: 30px;
  }

  .leftShadow {
    display: none;
  }

  .rightShadow {
    display: none;
  }

  .ratingSubDiv {
    margin-bottom: 0;
  }

  .allCarnivalSubDiv {
    width: 100%;
    margin-top: -10px;
  }
  .albumsSubDiv {
    width: 46%;
  }
  .cardLoader {
    gap: 0px;
  }

  .allCarnivalMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
}
