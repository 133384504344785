@import url(../../css/variables.css);

.classifiedChatCardMainDiv {
  cursor: pointer;
}

.flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

.classifiedChatCardAvatar {
  min-width: 45px;
  min-height: 45px;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  background-color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classifiedChatCardDiv2 {
  width: auto;
  max-width: 450px;
  background-color: rgba(43, 43, 43, 0.6);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 15px;
}

.classifiedChatCardUsername {
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

.classifiedChatCardText {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
}

.classifiedCardAvatar {
  min-width: 45px;
  min-height: 45px;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  object-fit: cover;
}
