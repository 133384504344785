@import url(../../css/variables.css);

.playerGradientBg {
  background: linear-gradient(180deg, #608FAF 6.49%, #ABC4D3 40.68%, #7199B5 99.5%);
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  position: fixed;
}

.cdBg {
  background-size: 550px 550px;
  background-repeat: no-repeat;
  background-image: url("./backgroundCD.png");
  background-position: center;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.cdBg::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.fdr{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.downArrowDiv {
  width: 50%;
  margin-top: 5px;
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.crossArrowDiv {
  width: 50%;
  margin-top: 5px;
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.downArrow {
  color: #f50101;
  font-size: 28px !important;
  cursor: pointer;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 0px;
  align-items: center;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.musicTitle {
  font-size: var(--heading);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  margin-top: 14px;
}

.musicSubTitle {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: black;
  margin-top: 8px;
}
.noData {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  margin-top: 8px;
}

.player {
  font-size: var(--text);
  font-weight: 400;
  font-family: Inter;
  color: #d9d9d9;
  margin-top: 6px;
}

.thumbnail {
  width: 50vh;
  height: 50vh;
  margin-top: 10px;
}

.queueIcon {
  color: #6a0000;
  font-size: 28px !important;
}

.songListDiv {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 355px;
  overflow-y: auto;
  width: 99%;
}

.songListDiv::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.queueDiv {
  width: 96%;
  margin: auto;
  padding-top: 0px;
}

.textColor {
  color: #6a0000;
  font-size: var(--title);
  font-weight: 600;
  font-family: Inter;
}

.queueText {
  color: #6a0000;
  font-size: var(--subTitle);
  font-weight: 600;
  font-family: Inter;
}

.queueTitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.moreFromText {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: var(--subTitle);
  color: #6a0000;
  font-weight: 600;
  font-family: Inter;
}

.flexDr {
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 100%;
  overflow-x: auto;
  height: auto;
  padding-bottom: 10px;
}

.flexDr::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.flexDr::-webkit-scrollbar {
  height: 4px !important;
}

.moreCardDiv{
  width: 70px;
  height: 70px;
}

@media only screen and (max-width: 431px) {
  .downArrowDiv {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .crossArrowDiv {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .title {
    margin: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 5%;
    justify-content: space-between;
  }

  .cdBg {
    background-position: left;
    background-position-x:150px;
  }

  .musicTitle {
    margin-top: 6%;
  }

  .musicSubTitle {
    margin-top: 3%;
  }

  .player {
    margin-top: 4%;
  }

  .mainDiv img {
    width: 90%;
    height: auto;
    margin-top: 7%;
  }

  .songListDiv {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }

  .queueDiv {
    width: 96%;
    margin: auto;
    padding-top: 20px;
  }

  .queueTitleDiv {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .moreFromText {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
