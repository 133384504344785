@import url(../../css/variables.css);

.mainDiv {
  background-color: #212121;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fullHeightAds {
  display: flex !important;
  justify-content: center;
}

.BannerAds {
  width: 80%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
}

.BannerAds ins {
  width: 100% !important;
  max-width: 100%;
  height: auto !important;
}

.marqueeDiv {
  height: 100%;
}
.socialsText {
  color: #fff;
  font-size: var(--text) !important;
  font-family: Inter !important;
  font-weight: 500 !important;
}

.marquee {
  color: #ff0000;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
}

.subDiv {
  margin-top: 30px;
  margin-bottom: 30px;
}

.imgDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerImage {
  width: 70%;
  height: 80%;
}

.navigateDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.navigate {
  color: #fff !important;
  text-decoration: none !important;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 500;
  transition: all 0.3s ease-out;
}

.navigate:hover {
  color: #ff0000 !important;
}

.rightDiv {
  display: flex;
  color: #fff;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 500;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.copyrightDiv {
  display: flex;
  height: 15%;
  color: gray;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 15px;
  align-items: center;
}

.icon {
  font-size: 20px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.icon:hover {
  color: #ff0000;
}

@media screen and (max-width: 1024px) {
  .BannerAds {
    width: 100%;
  }
}

@media screen and (max-width: 431px) {
  .fullHeightAds {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .footerImage {
    width: 80%;
    height: 100%;
  }
}
