@import url(../../css/variables.css);

.mainDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.mgb {
  margin-bottom: 40px;
}

.mgb25{
  margin-bottom: 25px;
}

.startButton{
  color: #ff0000;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.startButton:hover{
  color: #fff;
  text-decoration: underline;
}

.noItems{
  color: #fff;
  font-size: var(--heading);
  font-family: "Inter",sans-serif;
  font-weight: 600;
  text-align: center;
}
.noItemsSub{
  color: #fff;
  font-size: var(--body);
  font-family: "Inter",sans-serif;
  font-weight: 600;
  text-align: center;
}

.videoDiv{
  position: relative;
}

.dltDiv{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #343232;
  position: absolute;
  top: 10px;
  right: -10px;
  cursor: pointer;
}

.description{
  color: #fff;
  font-size: var(--body);
  font-family: "Inter",sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.viewDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: var(--text);
  color: #fff;
  font-family: Inter;
  font-weight: 400;
  cursor: pointer;
}

.ChevronRightIcon {
  font-size: var(--title) !important;
  color: #fff;
}

.closeIcon {
  font-size: 24px !important;
  color: #fff;
}

.dltIcon {
  font-size: 16px !important;
  color: #fff;
}

.divMain {
  width: 100%;
}

.flexDrC{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.eventTag {
  margin-bottom: 15px;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.subBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px;
  background-color: #343232;
  border-radius: 24px;
  box-shadow: 24px;
  max-height: 350px;
  padding: 20px;
  overflow: hidden;
}

.head {
  color: #fff;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 400;
}

.mgTop {
  margin-top: 24px;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
}

.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.chipsArray {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  margin-bottom: 27px;
}

.albumsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.albumsSubDiv {
  width: auto;
}

.notificationsDiv {
  display: flex;
  justify-content: center;
}

.notificationsSubDiv {
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 20px;
}

.tabPanel {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 900px) {
  .notificationsSubDiv {
    width: 100%;
  }
}

@media only screen and (max-width: 431px) {
  .subBox {
    width: 90%;
  }
}
