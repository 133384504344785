@import url(../../../css/variables.css);

.title {
  color: #fff;
  font-size: var(--heading);
  font-family: Inter;
  font-weight: 400;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.subTitleDiv {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.subTitle {
  font-family: Inter;
  font-size: var(--body);
  color: #fff;
  font-weight: 500;
}
