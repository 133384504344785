body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3a3a3a;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Default selection styling */
::selection {
  background: #3399ff !important; /* Highlight color */
  color: #fff; /* Text color */
}

/* For Mozilla Firefox */
::-moz-selection {
  background: #3399ff !important; 
  color: #fff;
}

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Italic';
  src: local('Inter-Italic'), url(./assets/fonts/Inter-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url(./assets/fonts/Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-BoldItalic';
  src: local('Inter-BoldItalic'), url(./assets/fonts/Inter-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter-SemiBold'), url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-SemiBoldItalic';
  src: local('Inter-SemiBoldItalic'), url(./assets/fonts/Inter-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-ExtraBold';
  src: local('Inter-ExtraBold'), url(./assets/fonts/Inter-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-ExtraBoldItalic';
  src: local('Inter-ExtraBoldItalic'), url(./assets/fonts/Inter-ExtraBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Light';
  src: local('Inter-Light'), url(./assets/fonts/Inter-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-LightItalic';
  src: local('Inter-LightItalic'), url(./assets/fonts/Inter-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url(./assets/fonts/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-MediumItalic';
  src: local('Inter-MediumItalic'), url(./assets/fonts/Inter-MediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Thin';
  src: local('Inter-Thin'), url(./assets/fonts/Inter-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-ThinItalic';
  src: local('Inter-ThinItalic'), url(./assets/fonts/Inter-ThinItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Black';
  src: local('Inter-Black'), url(./assets/fonts/Inter-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'LondrinaOutline-Regular';
  src: local('LondrinaOutline-Regular'), url(./assets/fonts/LondrinaOutline-Regular.ttf) format('truetype');
}


@media only screen and (max-width: 834px) {
  ::-webkit-scrollbar{
    width: 0;
  }
}