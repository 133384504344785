@import url(../../css/variables.css);

.songBox {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
}

.songSubBox {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.box {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.queue {
  background-image: url(../../assets/images/QueueIcon.png);
  width: 20px;
  height: 25px;
  background-size: 20px 25px;
  display: flex;
  align-self: center;
  margin-right: 10px;
}
.queue:hover {
  cursor: grab;
}

.threeDots{
  color: #D9D9D9;
  font-size: 18px !important;
  cursor: pointer;
}

.imageDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.thumbnail{
  width: 100%; 
  height: 100%; 
  object-fit: cover
}

.playArrow{
  position: absolute;
   font-size: 45px !important; 
   color: #D9D9D9
}

.contentDiv{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  width: auto;
}

.fdr{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

@media only screen and (max-width: 431px) {
  .songSubBox {
    width: 75%;
  }

  .imageDiv{
    width: 60px;
    height: 60px;
  }

  .contentDiv{
    width: 60%;
  }
}
