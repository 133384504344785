@import url(../../css/variables.css);

.inboxCardMainDiv {
  width: 100%;
  cursor: pointer;
}

.flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

.classifiedChatCardAvatar {
  min-width: 45px;
  min-height: 45px;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  background-color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classifiedChatCardUsername {
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  color: #fff;
}

.inboxCardAvatar {
  min-width: 45px;
  min-height: 45px !important;
  width: 45px !important;
  height: auto !important;
  object-fit: cover;
}

.inboxCardDiv2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
}

.inboxCardUsername {
  font-size: var(--heading);
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  flex-wrap: wrap;
}

.inboxCardText {
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  color: #7e7e7e;
  flex-wrap: wrap;
}