.eventSlidesDiv {
  width: 100%;
}

.mgb {
  margin-bottom: 40px;
}

.subText {
  text-align: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
}

@media only screen and (max-width: 900px) {
  .eventSlidesDiv {
    width: 95%;
  }
}
