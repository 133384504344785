@import url(../../css/variables.css);

.mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.mgb {
  margin-bottom: 30px;
}

.mgbt {
  margin-top: 20px;
  margin-bottom: 30px;
}

.flexDrR1 {
  display: flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
}

.fullWidthContainer {
  width: 100%;
}

.fullWidthContainerFd {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tabsMainDiv {
  position: absolute;
  top: 0;
  left: 35%;
  display: flex;
  align-self: center;
}

.container {
  position: relative;
  display: flex;
  margin-bottom: 40px;
}

.gridContainer2 {
  padding-left: 20px;
}

.redHead {
  font-size: var(--subTitle);
  font-weight: 700;
  font-family: Inter;
  color: red;
  margin-bottom: 20px;
}

.topBox {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 5px;
}
.mgb20 {
  margin-bottom: 20px;
}

.top {
  color: #fff;
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.top:hover {
  color: #ff0000;
}

.trendingDiv {
  position: relative;
  display: flex;
  justify-content: center;
}

.playDiv {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: rgba(185, 6, 6, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 45px;
}

.heading {
  color: #fff;
  font-size: var(--heading);
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.trendingContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flexDr1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.flexDr2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 20px;
}

.sortBy {
  width: 200px;
}

.sortDiv {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.ascDescIcon {
  font-size: 18px !important;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1 {
  font-size: 18px !important;
  color: #ff0000;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.ascDescIcon1:hover {
  color: #fff;
}

.ascDescIcon:hover {
  color: #ff0000;
}

.firstContainer {
  width: 75%;
}

.secondContainer {
  width: 100%;
}

.calendar {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  margin-top: 40px;
}

.filter {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calendarHead {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
}
.date {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
}

.to {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.skeleton {
  flex-direction: row;
}

.flexDr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.mgb20 {
  margin-bottom: 20px;
}
.iconsSm {
  display: none;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
}

.iconsSmDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.calendarSm {
  font-size: 20px !important;
  color: #fff;
  cursor: pointer;
}

.plus {
  font-size: 14px !important;
  color: #fff;
  font-weight: 400;
  margin-right: 10px;
}

.filterSm {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.upDown {
  font-size: 14px !important;
  color: #fff;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.smBanner {
  display: none;
}

.BannerAds {
  width: 100%;
  height: auto;
  margin-top: 30px;
  max-width: 100%;
  overflow: hidden;
}

.BannerAds ins{
  width: 100% !important;
  max-width: 100%;
  height: auto !important;
}

@media only screen and (max-width: 900px) {
  .BannerAds {
    margin-bottom: 30px;
  }

  .smBanner {
    display: flex;
  }

  .container {
    flex-direction: column;
  }

  .tabsMainDiv {
    position: relative;
    margin-top: 30px;
    left: 0;
    align-self: flex-start;
  }

  .gridContainer2 {
    margin-top: 30px !important;
    padding-left: 0;
  }

  .trendingContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }

  .iconsSm {
    display: flex;
  }
  .submitImg {
    display: none;
  }

  .submitImgSm {
    display: flex;
  }

  .firstContainer {
    width: 100%;
  }

  .secondContainer {
    display: none;
  }

  .flexDr {
    justify-content: space-between;
  }

  .filter {
    margin-bottom: 20px;
  }

  .calendar {
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .buttonDiv {
    flex-direction: column;
  }

  .customButton {
    width: 85% !important;
  }
}

@media only screen and (max-width: 431px) {
  .trendingContainer {
    flex-direction: column;
  }

  .flexDr1 {
    flex-direction: column;
  }

  .sortBy {
    width: 80%;
  }

  .sortDiv {
    margin-bottom: 30px;
    width: 100%;
  }
}
