@import url(../../css/variables.css);

.excerpt {
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  color: #ffffff;
  margin-top: 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  max-width: 96%;
}

.blogGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.blogImgDiv {
  filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.blogDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 0px;
  gap: 15px;
}

.title {
  font-size: var(--heading);
  font-weight: 500;
  font-family: Inter;
  color: #fb1e1e;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.subTitle {
  font-size: var(--body);
  color: #e4e4e4;
  margin-top: 0px;
  font-weight: 500;
  font-family: Inter;
  font-style: italic;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  margin-top: 0px;
  max-height: 35px;
  overflow: hidden;
}

.chipsA {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  margin-top: 0px;
  max-height: 35px;
  overflow: hidden;
}

.footerText {
  font-size: var(--text);
  color: #b4b4b4;
  margin-top: 0px;
}

.title:hover {
  color: #ff0000;
}

.blogGrid:hover ~ div .blogDiv .title {
  color: #ff0000;
}

.mainDiv {
  transition: all 0.3s ease-out;
}

.mainDiv:has(.blogGrid:hover) {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0px 2px 5px 0px #171717;
}
.mainDiv:has(.title:hover) {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0px 2px 5px 0px #171717;
}

@media only screen and (max-width: 600px) {
  .blogDiv {
    margin-left: 0px;
    margin-top: 16px;
  }
}
