@import url(../../css/variables.css);

.heading {
  color: #ff0000;
  font-size: var(--subTitle);
  font-family: Inter;
  font-weight: 700;
  margin-bottom: 30px;
}

.mgb {
  margin-bottom: 40px;
}

.flexDir {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  align-items: center;
  cursor: pointer;
}

.authorImage {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
}

.authorContentDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.authorTitle {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
}

.authorReviews {
  color: #fff;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 400;
}

.galleryContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.flexDrC {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.subText {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
}

.oneLineText {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.locationIcon {
  color: #ff0000;
  font-size: 14px !important;
}

.calendarIcon {
  color: #ff0000;
  font-size: 12px !important;
}

.ratingCarnival {
  display: flex;
  align-items: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 400;
  color: #fff;
}

.star {
  color: yellow;
  font-size: 22px;
  margin-left: 2px;
  margin-right: 2px;
}

.top {
  color: #fff;
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.top:hover {
  color: #ff0000;
}

.topBox {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 3px;
  margin-bottom: 30px;
}

.topImgContainer {
  display: flex;
  flex-direction: row !important;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}

.topImgContainer:hover .top {
  color: #ff0000;
}

.div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconDiv {
  display: flex;
  color: #ff0000;
  margin-right: 5px;
  width: 20px;
  align-items: center;
  justify-content: center;
}

.minWid {
  min-width: 26px;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.promoterIcon {
  font-size: 26px !important;
  color: #fff;
}
.authorIcon {
  font-size: 50px !important;
  color: #fff;
}

.promoterImg {
  height: 24px;
  width: 24px;
  border-radius: 50px;
  object-fit: cover;
}
