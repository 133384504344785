@import url(../../css/variables.css);

.chipsArray {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  margin-bottom: 27px;
}

.mgt{
  margin-top: 20px;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.firstContainer {
  width: 73%;
}

.secondContainer {
  width: 27%;
}

.smBanner {
  display: none;
}


.BannerAds {
  width: 100%;
  height: auto;
  margin-top: 30px;
  max-width: 100%;
  overflow: hidden;
}

.BannerAds ins{
  width: 100% !important;
  max-width: 100%;
  height: auto !important;
}


.highlightedText {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 600;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
  word-break: break-all;
  cursor: pointer !important;
  transition: all 0.3s ease-out;
}
.highlightedText:hover {
  color: #ff0000;
  font-weight: 600;
}

.submitImgSm {
  width: 100%;
  height: auto;
  cursor: pointer;
  display: none;
  margin-bottom: 30px;
}
.submitImg {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.fullWidthContainer {
  width: 100%;
}
.fullWidthContainerFd {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.fullWidthContainerFdS {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.subText {
  text-align: center;
  font-size: var(--text);
  font-family: Inter;
  font-weight: 600;
  color: #fff;
  gap: 5px;
}

.mgb {
  margin-bottom: 40px;
}

.calendar {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  margin-top: 40px;
}

.filter {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.noDataImg {
  width: 90%;
  height: auto;
}

.calendarHead {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 500;
}
.date {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
}
.advance {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 700;
}

.to {
  color: #fff;
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mgb20 {
  margin-bottom: 20px;
}

.flexDr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.flexDrR {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.iconsSm {
  display: none;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
}

.iconsSmDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.calendarSm {
  font-size: 20px !important;
  color: #fff;
  cursor: pointer;
}

.plus {
  font-size: 14px !important;
  color: #fff;
  font-weight: 400;
  margin-right: 10px;
}

.filterSm {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.dotSize {
  font-size: 5px;
  margin-top: 3px;
}
.upDown {
  font-size: 14px !important;
  color: #fff;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.topBox {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 5px;
}

.topImgContainer {
  display: flex;
  flex-direction: row !important;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}

.topImgContainer:hover .top {
  color: #ff0000;
}

.minWid {
  min-width: 26px;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}


.promoterIcon {
  font-size: 26px !important;
  color: #fff;
}

.promoterImg {
  height: 24px;
  width: 24px;
  border-radius: 50px;
  object-fit: cover;
}

.artist {
  display: flex;
  align-items: center;
  font-size: var(--smallText);
  font-family: Inter;
  font-weight: 500;
  color: #c8c8c8;
}

.top {
  color: #fff;
  font-size: var(--body);
  font-weight: 500;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.top:hover {
  color: #ff0000;
}

.redHead {
  font-size: var(--subTitle);
  font-weight: 700;
  font-family: Inter;
  color: red;
  margin-bottom: 20px;
}

.posterDiv {
  display: flex;
  justify-content: center;
}

.poster {
  width: 80%;
  height: auto;
}

.body {
  font-size: var(--body);
  font-family: Inter;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}

.body p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.body table,
.body tbody,
.body tr,
.body td {
  max-width: 100%;
}

.body a {
  color: yellow !important;
}

.body img {
  max-width: 100%;
  height: auto;
}


.customButton {
  background-color: #0366ff !important;
  font-size: var(--body) !important;
  color: #fff !important;
  border-radius: 0 !important;
  text-transform: none !important;
  width: 80%;
  height: 36.5px !important;
}

.ratingMainDiv {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.ratingSubDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.eventDenoter {
  font-size: var(--text);
  color: #fff;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.italic {
  font-style: italic;
}

.ratingDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ratingStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: var(--body);
  font-weight: 600;
  font-family: Inter;
  width: 100%;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.eventSlidesDiv {
  width: 100%;
}

.ratingHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  width: 100%;
  margin-bottom: 23px;
}

.ratingParam {
  min-width: 30%;
}

.rating {
  width: 33%;
}

.locationIcon {
  font-size: 20px !important;
  color: #ff0000;
}

.imgIcon {
  min-height: 200px;
  height: auto;
  width: 100%;
  margin-top: 10px;
}

.headingStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  gap: 6px;
}

.headingStyleMgb {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff0000;
  font-size: var(--heading);
  font-weight: 700;
  font-family: Inter;
  gap: 6px;
  margin-bottom: 20px;
}

.contentStyle {
  color: #fff;
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.contentStyleC {
  color: #fff;
  font-size: var(--body);
  font-weight: 400;
  font-family: Inter;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

.gridGeneral {
  border-right: 1px solid #ff0000;
  padding-right: 20px;
}
.gridGeneralL {
  border-left: 1px solid #ff0000;
  padding-left: 20px;
  display: flex;
  flex-direction: column !important;
  gap: 20px;
}

.spacingLR {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column !important;
  gap: 20px;
}

.timeFontIcon {
  font-size: 20px !important;
  color: #ff0000;
}

.contentDiv {
  width: 100%;
  margin-bottom: 40px;
}

.calendarImage {
  height: 30px;
  width: auto;
  cursor: pointer;
}

.flexGap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.flexButton {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 40%;
  align-items: center;
}

.text {
  font-size: var(--text);
  font-family: Inter;
  font-weight: 500;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-out;
}

.text:hover {
  color: #ff0000;
}

.showDate {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  margin-bottom: 27px;
}

.time {
  font-size: var(--text);
  font-family: Inter;
  font-weight: 400;
  color: #fff;
}

.calendarIcon {
  color: #ff0000;
  font-size: 12px !important;
}

.advanceFlexDr{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.UpDownIcon{
  color: #fff !important;
  font-size: 18px !important;
}

@media only screen and (max-width: 834px) {
  .flexButton {
    width: 100%;
  }

  .eventSlidesDiv {
    width: 95%;
  }

  .ratingStyle {
    justify-content: space-between;
  }

  .ratingHead {
    gap: 0px;
    margin-top: 20px;
  }

  .ratingSubDiv {
    margin-bottom: 0;
  }

  .spacingLR {
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .gridLeftPadding {
    padding-left: 0px;
  }

  .gridGeneral {
    border-right: 0;
    padding-right: 0;
  }

  .gridGeneralL {
    border-left: 0;
    padding-left: 0;
    gap: 30px;
  }

  .poster {
    width: 100%;
    height: auto;
  }

  .iconsSm {
    display: flex;
  }
  .submitImg {
    display: none;
  }

  .submitImgSm {
    display: flex;
  }

  .firstContainer {
    width: 100%;
  }

  .secondContainer {
    display: none;
  }

  .BannerAds {
    margin-bottom: 30px;
  }

  .smBanner {
    display: flex;
  }

  .flexDr {
    justify-content: space-between;
  }

  .filter {
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .calendar {
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .buttonDiv {
    flex-direction: column;
  }

  .customButton {
    width: 85% !important;
  }

  .heading {
    margin-bottom: 0px;
  }

  .subHeading {
    margin-top: 20px;
  }

  .gridBorderRight {
    border-right: 0;
  }
}
