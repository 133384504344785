@import url(../../css/variables.css);

.cardParent {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px,
}

.listName {
  font-size: var(--subTitle);
  color: #ffffff;
  font-family: Inter;
  font-weight: 700;
}

.songListItemContainer {
  display: flex;
  flex-direction: column;
  padding: 5px; /* Adjust as needed */
}

.songListItemText {
  font-size: var(--body);
  font-family: Inter;
  font-weight: 600;
}

.songListItemSubText {
  font-size: var(--smallText);
  font-family: Inter;
}

.songListItemNumber {
  display: flex;
  padding-right: 5px; /* Adjust as needed */
  font-size: var(--body);
  width: 20px;
  font-weight:600;
  font-family: Inter;
}

.trackList {
  display: flex;
  flex-direction: column; /* Stack tracks vertically */
  gap: 5px; /* Add gap between tracks */
  padding-bottom: 35px;
}

.songListItemParent {
  display: flex;
  align-items: center;
  border-bottom: 1px solid ;
  min-height: 40px;
}

.playIcon {
  margin-left: auto;
  font-size: 30px !important;
  cursor: pointer;
}
