@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');
@import url("https://cdn.jsdelivr.net/npm/@ckeditor/ckeditor5-image@41.4.2/dist/index.css");

:root {
    --h1: 32px;
    --title: 20px;
    --subTitle: 18px;
    --body: 14px;
    --heading: 16px;
    --text: 12px;
    --smallText: 10px;
}

